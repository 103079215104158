@import "../../../assets/styles/variables";

:global {
  .ant-image {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    // background: #f1f1f1;
    border-radius: 5px;
    overflow: hidden;
    margin: 0;
    .ant-image-img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }

  .b-robot {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    height: min-content;
    &.disabled {
      mix-blend-mode: luminosity;
    }
    .b-icon {
      position: absolute;
      right: -0.2rem;
      top: 0;
      transform: translate(50%, -50%);
      width: 1rem;
      height: 1rem;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.check {
          display: inline;
          polyline {
            -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
            animation: checkmark 0.25s ease-in-out 0.7s backwards;
          }
          circle {
            -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
            animation: checkmark-circle 0.6s ease-in-out backwards;
          }
          circle#colored {
            -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
            animation: colored-circle 0.6s ease-in-out 0.7s backwards;
          }
        }
      }
    }
  }

  .card-part {
    position: relative;
    background-color: #ffffff;
    padding: 1.25rem 1rem;
    border: 1px solid #f0f0f0;
    border-radius: 0.8rem;
    box-shadow: 0 0.5rem 1rem 0.5rem rgba(179, 169, 148, 0.2);
    margin-bottom: 1.5rem;
    transition: all 1s linear;
    overflow: hidden;

    &.no-border {
      border: none;
      box-shadow: none;
      padding: 0;
    }

    .ant-alert {
      align-items: flex-start;
      svg {
        margin-top: 0.25rem;
        width: 1.25rem;
        height: auto;
      }

      &.ant-alert-info {
        svg {
          fill: #13607c;
        }
      }
    }

    .part-b-left {
      position: relative;
      width: 21rem;
      @media #{$mobile} {
        width: 100%;
        margin-bottom: 1.5rem;
      }

      .ant-image,
      .part-svg {
        background-color: #f1f1f1;
        height: 12rem;
      }

      label {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .b-uploading {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        color: var(--bs-primary);
        background: #ffffff44;
        backdrop-filter: blur(4px);
        z-index: 2;
      }
    }

    .part-info {
      border-left: 1px solid #f0f0f0;

      @media #{$mobile} {
        border: none;
      }
      .b-field {
        margin-bottom: 1.5rem;
        label {
          &:first-child {
            color: #998c70;
            font-size: 0.857rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .b-calculate {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: rgba(rgb(255, 238, 202), 0.2);
      backdrop-filter: blur(2px);
      color: var(--bs-primary);

      transition: all 0.2s linear;

      opacity: 0;
      z-index: -1;

      &.active {
        opacity: 1;
        z-index: 9;
      }
    }

    hr {
      margin-top: 0;
      border-top: 1px solid #adadad;
      box-shadow: 0px 24px 32px rgba(179, 169, 148, 0.3);
    }
  }

  .unfolding-preview {
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 80vh;
    }
  }

  .layer-type {
    min-height: 28px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    .ant-dropdown-trigger {
      line-height: 24px;
    }
  }

  .part-action {
    @media #{$mobile} {
      width: 100%;
      .ant-space-item {
        width: 100%;
      }
      .ant-btn-compact-first-item {
        width: 100%;
      }
    }
  }

  .table-price-reference {
    tr {
      cursor: pointer;

      &:hover {
        td {
          background-color: rgba(var(--bs-primary-rgb), 0.2);
        }
      }
    }
  }
}
